<template>
  <div>
    <van-nav-bar :title="$route.meta.title" left-arrow @click-left="toStudent" />
    <van-cell-group v-for="(sdt,index) in dataList" :key="index" :title="sdt.cnName">
      <template v-if="sdt.classHourVoList.length > 0">
        <van-cell v-for="(item, idx) in sdt.classHourVoList" :key="idx" :title="'课程：' + item.courseSetting.title" value="详情" is-link center
          @click="toClassHourLogList(sdt, item)">
          <template #label>
            <span v-if="item.accountType === 0">
              <van-tag type="warning">课时</van-tag> [总计:{{ item.totalClassHour }} / 已用:{{ item.usedClassHour }} /
              剩余:{{ item.totalClassHour - item.usedClassHour }} ]
            </span>
            <span v-if="item.accountType === 1">
              <van-tag type="warning">截止日</van-tag> [{{ item.expiryDate }}]
            </span>
          </template>
        </van-cell>
      </template>
      <template v-else>
        <van-cell title="暂未设置课时账户" />
      </template>
    </van-cell-group>
  </div>
</template>
<script>
import guardianApi from '@/api/guardian'
export default {
  data() {
    return {
      appId: '',
      dataList: []
    }
  },
  created() {
    this.appId = this.$route.params.appId
  },
  mounted() {
    this.initDataList()
  },
  methods: {
    initDataList() {
      guardianApi.classHourList().then(res => {
        if (res.succ) {
          this.dataList = res.data.items
        }
      })
    },
    toClassHourLogList(sdt, item) {
      this.$router.push(`/${this.appId}/guardian/classHourLog/list/${item.id}/${sdt.id}?courseTitle=${item.courseSetting.title}`)
    },
    toStudent() {
      this.$router.push(`/${this.appId}/guardian`)
    }
  }
}
</script>
<style lang="less" scoped>
.van-cell__title {
  flex: 2;
}
</style>
